import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";

const Tags = ({ pageContext, data, uri }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMdx;
  const tagHeader = `Posts tagged “${tag}”`;
  return (
    <Layout
      pageTitle={tagHeader}
      pageDescription={`All posts tagged “${tag}”`}
      pageCanonical={`${data.site.siteMetadata.siteUrl}${uri}`}
    >
      <h1 className="page-header">
        {tagHeader} ({totalCount})
      </h1>
      <ul>
        {edges.map(({ node }) => {
          const { slug } = node.fields;
          const { title } = node.frontmatter;
          return (
            <li key={slug}>
              <Link to={slug}>{title}</Link>
            </li>
          );
        })}
      </ul>

      <Link to="/tags/">All tags</Link>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    allMdx(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default Tags;
